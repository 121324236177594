body {
    background-image: url(./background2.gif); 
    margin: 0;
    background-size: no-repeat;
    position: relative;
}

html {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.pfp img {
    width: 100%;
    height: auto;
    margin-top: -50%;
    flex-shrink: 1;
}

.pfp {
   display: inline-block;
   position: relative;
   width: 60%;
   overflow: hidden;
}

.card-header {
    font-weight: Bold;
    font-size: 2em;
}